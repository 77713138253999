module.exports = [{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-4860091-1"],"pluginConfig":{"anonymize_ip":true,"head":false,"exclude":["/preview/**"]},"gtagConfig":{"respectDNT":true}},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"cede68eb11674ec28cd2da13d1d7a7f6","captureUncaught":true,"captureUnhandledRejections":true,"payload":{}},
    },{
      plugin: require('/opt/render/project/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
